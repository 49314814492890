
import collect from 'collect.js'

export default {
  asyncData ({ app, query }) {
    const images = [{
      urlImage: '/images/index/tom-chen--h-OMkA5JTM-unsplash.jpg',
      photographer: 'tomchen'
    }, {
      urlImage: '/images/index/david-rodrigo-Fr6zexbmjmc-unsplash.jpg',
      photographer: 'david__r'
    }, {
      urlImage: '/images/index/david-rodrigo-kZ1zThg6G40-unsplash.jpg',
      photographer: 'david__r'
    }, {
      urlImage: '/images/index/andreas-m-BLGcfeNnx4U-unsplash.jpg',
      photographer: 'nextvoyage_pl'
    }, {
      urlImage: '/images/index/alexander-kaunas-xEaAoizNFV8-unsplash.jpg',
      photographer: 'akaunas'
    }, {
      urlImage: '/images/index/valeriy-ryasnyanskiy-bduYp1pYxjM-unsplash.jpg',
      photographer: 'ryasnyansky'
    }, {
      urlImage: '/images/index/paolo-nicolello-2gOxKj594nM-unsplash.jpg',
      photographer: 'paul_nic'
    }, {
      urlImage: '/images/index/sara-dubler-Koei_7yYtIo-unsplash.jpg',
      photographer: 'ahungryblonde_'
    }, {
      urlImage: '/images/index/the-anam-J9O3WcJ5N74-unsplash.jpg',
      photographer: 'theanamcxr'
    }, {
      urlImage: '/images/index/lucas-davies-iP8ElEhqHeY-unsplash.jpg',
      photographer: 'lucas_davies'
    }, {
      urlImage: '/images/index/jamie-davies-7iNteV_zTRU-unsplash.jpg',
      photographer: 'jamie_davies'
    }, {
      urlImage: '/images/index/veliko-karachiviev-hSvagWirWPA-unsplash.jpg',
      photographer: 'ikoveliko'
    }, {
      urlImage: '/images/index/leonard-cotte-R5scocnOOdM-unsplash.jpg',
      photographer: 'ettocl'
    }, {
      urlImage: '/images/index/florian-wehde-milUxSbp4_A-unsplash.jpg',
      photographer: 'florianwehde'
    }, {
      urlImage: '/images/index/fabien-maurin-x-S6ZlJ6dP0-unsplash.jpg',
      photographer: 'abienmaurin'
    }, {
      urlImage: '/images/index/sumit-chinchane-jWKk-0ZBUyg-unsplash.jpg',
      photographer: 'sumit_ac'
    }, {
      urlImage: '/images/index/mathew-schwartz-gsllxmVO4HQ-unsplash.jpg',
      photographer: 'cadop'
    }, {
      urlImage: '/images/index/mg-cthu--1h_NN3nqzI-unsplash.jpg',
      photographer: 'mgcthu'
    }, {
      urlImage: '/images/index/david-kohler-VFRTXGw1VjU-unsplash.jpg',
      photographer: 'davidkhlr'
    }]

    let checkIn = app.$moment().locale('en').format('YYYY-MM-DD')
    let checkOut = app.$moment().locale('en').add(1, 'days').format('YYYY-MM-DD')
    let guests = [{ adults: 2, children: [] }]

    if (query.check_in && query.check_in >= app.$moment().locale('en').format('YYYY-MM-DD')) {
      checkIn = app.$moment(query.check_in).locale('en').format('YYYY-MM-DD')
      if (query.check_out && query.check_out > query.check_in) {
        if (app.$moment(query.check_out).diff(app.$moment(query.check_in), 'days') > 30) {
          checkOut = app.$moment(checkIn).locale('en').add(30, 'days').format('YYYY-MM-DD')
        } else {
          checkOut = app.$moment(query.check_out).locale('en').format('YYYY-MM-DD')
        }
      } else {
        checkOut = app.$moment(query.check_in).locale('en').add(1, 'days').format('YYYY-MM-DD')
      }
    }

    if (query.check_out && query.check_out > checkIn) {
      if (app.$moment(query.check_out).diff(app.$moment(checkIn), 'days') > 30) {
        checkOut = app.$moment(checkIn).locale('en').add(30, 'days').format('YYYY-MM-DD')
      } else {
        checkOut = app.$moment(query.check_out).locale('en').format('YYYY-MM-DD')
      }
    }

    if (query.guests) {
      guests = query.guests.split('|').map((r) => {
        return {
          adults: r.split(',').filter(v => v === 'a').length,
          children: r.split(',').filter(v => v > 0)
        }
      })
    }

    return {
      selectedImage: images[Math.floor(Math.random() * images.length)],
      aggregator: 'travolutionary',
      destination: null,
      start: checkIn,
      end: checkOut,
      rooms: guests,
      schemaOrganization: {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        url: 'https://www.travelor.com',
        logo: 'https://www.travelor.com/icon.png'
      },
      schemaWebSite: {
        '@context': 'http://schema.org',
        '@type': 'WebSite',
        url: 'https://www.travelor.com',
        potentialAction: {
          '@type': 'SearchAction',
          target: 'https://www.travelor.com/hotels/search?search={query}',
          'query-input': 'required name=query'
        }
      }
    }
  },

  head () {
    return {
      title: this.whiteLabel.home_page_title ? this.whiteLabel.home_page_title : this.$i18n.t('Travelor - Hotels Worldwide'),
      titleTemplate: '%s',
      meta: [{
        hid: 'description',
        name: 'description',
        content: this.$i18n.t('Best price guaranteed, Big Savings booking hotels worldwide, up to 75% off on hotel reservations online.')
      }, {
        hid: 'keywords',
        name: 'keywords',
        content: this.whiteLabel.name ? this.whiteLabel.name : 'Travelor'
      }, {
        hid: 'og:type',
        name: 'og:type',
        content: 'company'
      }, {
        hid: 'og:title',
        name: 'og:title',
        content: this.whiteLabel.home_page_title ? this.whiteLabel.home_page_title : this.$i18n.t('Travelor - Hotels Worldwide')
      }, {
        hid: 'og:description',
        name: 'og:description',
        content: this.$i18n.t('Best price guaranteed, Big Savings booking hotels worldwide, up to 75% off on hotel reservations online.')
      }, {
        hid: 'og:site_name',
        name: 'og:site_name',
        content: this.whiteLabel.name ? this.whiteLabel.name : 'Travelor'
      }],
      __dangerouslyDisableSanitizers: ['script'],
      script: [{
        innerHTML: JSON.stringify(this.schemaOrganization),
        type: 'application/ld+json'
      }, {
        innerHTML: JSON.stringify(this.schemaWebSite),
        type: 'application/ld+json'
      }]
    }
  },

  created () {
    if (this.searches) {
      const last = collect(this.searches).last(item => item.aggregator === this.aggregator)

      if (last) {
        this.destination = last.destination
        this.start = last.params.check_in
        this.end = last.params.check_out
        this.rooms = last.params.guests.split('|').map((r) => {
          return {
            adults: r.split(',').filter(v => v === 'a').length,
            children: r.split(',').filter(v => v > 0)
          }
        })
      }
    }
  }
}
